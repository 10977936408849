@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

$main-color: #874137;
$secondary-color: #e8c1bc;
$purple-color: #424c65;
$yellow-color: #e29d20;
$white-color: #ffffff;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: 'Outfit';
    // background-color: $main-color;
    // color: $white-color;
}

@import './toolbar.scss';
@import './sideDrawer.scss';
@import './backDrop.scss';
@import './home.scss';
@import './footer.scss';
@import './about.scss';
@import './customers.scss';
@import './contact.scss';
