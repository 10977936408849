.home-container {
    width: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
}

.section-container {
    max-width: 1200px;
    width: 100%;
}

.mission-container {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 5px;
}

.mission-part-item {
    padding: 30px;
    text-align: center;
}

.mission-container .mission-part-item:nth-child(even) {
    background-color: $main-color;
    color: white;
    width: 25%;
}

.mission-container .mission-part-item:nth-child(odd) {
    background-color: $secondary-color;
    color: $main-color;
    width: 40%;
    padding-left: 100px;
    padding-right: 100px;
}

.mission-part-item-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.our-products-title {
    display: flex;
    justify-content: center;
    a {
        text-decoration: none;
    }
}

.our-products-title-font {
    font-size: 25px;
    background: $secondary-color;
    padding: 5px;
    color: white;
}

.products-container {
    display: flex;
    padding: 30px;
    justify-content: space-evenly;
}

.product-part-item-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    cursor: pointer;
}

.product-part-item {
    position: relative;
    max-width: 200px;
}

.product-part-item-pic {
    max-width: 200px;
    object-fit: contain;
}

.product-part-item-title {
    width: 100%;

    text-align: center;
    background-color: $main-color;
    color: white;
    padding: 5px;
}

.home-carousel-container {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.custom-carousel .carousel .slider-wrapper .slide img {
    width: 100%;
    object-fit: contain;
}

@media (max-width: 768px) {
    .mission-container {
        display: flex;
        flex-direction: column;
        padding: 0 30px 0 30px;
        gap: 30px;
    }
    .mission-container .mission-part-item:nth-child(even) {
        width: 100%;
    }

    .mission-container .mission-part-item:nth-child(odd) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .products-container {
        display: flex;
        flex-direction: column;
        padding: 30px;
        justify-content: space-evenly;
    }

    .product-part-item {
        position: relative;
        max-width: 100%;
    }

    .product-part-item-pic {
        max-width: 100%;
        object-fit: contain;
    }

    .home-carousel-container {
        height: 200px; /* Set the desired fixed height */
        display: flex;
        align-items: center;
        overflow: hidden;
    }
}
