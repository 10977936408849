.contact-container {
    margin-top: 10px;
    margin-bottom: 20px;
}

.contact-header {
    text-align: center;
    background-color: $secondary-color;
    color: $main-color;
    padding: 10px;
}

.contact-details {
    color: $main-color;
    padding: 50px;
}

.touch-input-box {
    display: flex;
    justify-content: space-between;
    padding: 0 50px 0 50px;
}

.touch-input-item {
    width: 48%;
}

.touch-input-style {
    border: 1px solid $main-color;
    background: transparent;
    width: 100%;
    height: 40px;
    padding: 10px;
    color: $main-color;
    outline-style: none;
    margin: 10px 0;
    &::placeholder {
        color: $secondary-color;
    }
}

.touch-textarea-style {
    width: 100%;
    height: 100px;
    margin: 10px 0;
    border: 1px solid $main-color;
    background: transparent;
    padding: 10px;
    color: $main-color;
    outline-style: none;
    &::placeholder {
        color: $secondary-color;
    }
}

.touch-button {
    width: 100%;
    height: 40px;
    margin: 5px 0;
    border: none;
    cursor: pointer;
    background-color: $secondary-color;
    color: $main-color;
}

@media (max-width: 768px) {
    .touch-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .touch-left {
        width: 100%;
    }

    .touch-right {
        width: 100%;
        padding-bottom: 100%;
    }

    .touch-input-box {
        display: flex;
        flex-direction: column;
    }

    .touch-input-item {
        width: 100%;
    }
}
