.product-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.product-title {
    text-align: center;
    background-color: $secondary-color;
    padding: 10px;
    color: $main-color;
}

.product-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 50px 10px 50px;
}

.product-item-container {
    width: 50%;
}

.product-item-title {
    text-align: center;
}

.product-item-carousel {
    width: 100%;
    padding: 20px;
}

@media (max-width: 768px) {
    .product-item-container {
        width: 100%;
    }
}
