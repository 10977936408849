.about-header {
    width: 100%;
    background-color: $secondary-color;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 5px;
}

.about-header-font {
    color: $main-color;
    text-align: center;
}

.about-top-container {
    padding: 30px;
    color: $main-color;
}

.about-top-item-title {
    margin-bottom: 20px;
}

.detail-item-content {
    margin-bottom: 30px;
}

.item-with-pic-container {
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.item-text {
    width: 50%;
}

.item-pic {
    width: 40%;
}

.item-pic-conifg {
    height: 200px;
    object-fit: contain;
}

.about-pic-list-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.pic-list-pic {
    height: 200px;
    object-fit: contain;
}

.bottom-header {
    display: flex;
    justify-content: space-between;
}

.bottom-item {
    width: 45%;
}

.header-pic {
    width: 100%;
    object-fit: contain;
}

.bottom-title {
    font-size: 50px;
    color: $main-color;
    padding: 20px;
}

.bottom-content {
    color: $main-color;
    padding: 30px;
    text-align: center;
}

@media (max-width: 768px) {
    .item-with-pic-container {
        display: flex;
        gap: 30px;
        flex-direction: column;
    }

    .item-text {
        width: 100%;
    }

    .item-pic {
        width: 100%;
    }

    .item-pic-conifg {
        width: 100%;
        object-fit: contain;
        margin-bottom: 30px;
    }

    .detail-item-content {
        margin-bottom: 30px;
    }

    .pic-list-pic {
        width: 18%;
        height: auto;
        object-fit: contain;
    }

    .header-pic {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .bottom-header {
        display: flex;
        flex-direction: column;
    }

    .bottom-item {
        width: 100%;
    }

    .bottom-title {
        font-size: 26px;
    }
}
