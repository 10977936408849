.footer-container {
    width: 100%;
    background: transparent;

    display: flex;
    justify-content: center;
}

.footer-box {
    max-width: 1200px;
    width: 100%;
    position: relative;
    height: 300px;
    display: flex;
    align-items: flex-end;
}

.footer-box::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $main-color;
    opacity: 0.6;
    z-index: 0; /* Place it behind other content */
}

.footer-box::after {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('https://res.cloudinary.com/djd56oewd/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1731345058/Aldima%20Global%20LLC./Home%20Page/New%20Footer%20Image.webp');
    background-size: cover; /* Ensure the image covers the container */
    background-position: center; /* Center the image */
    opacity: 0.6;
    z-index: -1; /* Place it behind other content */
}

.footer-content {
    width: 100%;
    background-color: $main-color;
    text-align: center;
    padding: 5px;
    color: white;
    z-index: 3;
}

@media (max-width: 768px) {
    .footer-box {
        height: 100px;
    }
}
