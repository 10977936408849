.toolbar {
    // position: fixed;
    width: 100%;
    background: transparent;

    display: flex;
    justify-content: center;

    .toolbar-container {
        max-width: 1200px;
        width: 100%;
        position: relative;
        .toolbar__navigation {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            // padding: 0 1rem;
        }

        .toolbar__logo {
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            a {
                color: white;
                text-decoration: none;
                font-size: 1.5rem;
            }
        }

        .toolbar__logo img {
            height: 100px;
            width: auto;
        }

        .toolbar_navigation-items {
            width: 100%;
            background-color: $main-color;
            z-index: 1;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                width: 80%;
                height: 35px;
                justify-content: space-evenly;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                // background-color: #ccc;
            }

            li {
                padding: 0 0.5rem;
                position: relative;
            }

            a {
                color: white;
                text-decoration: none;

                &:hover,
                &:active {
                    color: orange;
                }
            }
        }
    }
    .toolbar-container::before {
        content: ''; /* Required for pseudo-elements */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('https://res.cloudinary.com/djd56oewd/image/upload/c_fill,g_auto,h_250,w_970/b_rgb:000000,e_gradient_fade,y_-0.50/c_scale,co_rgb:ffffff,fl_relative,l_text:montserrat_25_style_light_align_center:Shop%20Now,w_0.5,y_0.18/v1731345058/Aldima%20Global%20LLC./Home%20Page/New%20Footer%20Image.webp');
        background-size: cover; /* Ensure the image covers the container */
        background-position: center; /* Center the image */
        filter: blur(20px); /* Apply blur only to the background */
        opacity: 0.3;
        z-index: 0; /* Place it behind other content */
    }
}

@media (max-width: 768px) {
    .toolbar_navigation-items {
        display: none;
    }

    .toolbar {
        padding: 0;
    }
    .toolbar {
        .toolbar-container {
            .toolbar__navigation {
                display: flex;
                flex-direction: row;
                padding: 0 1rem;
            }
            .toolbar__logo {
                margin-left: 1rem;
                height: 80px;
            }
            .toolbar__logo img {
                height: 50px;
                width: auto;
            }
        }
        .toolbar-container::before {
            content: none; /* Required for pseudo-elements */
        }
    }
}

@media (min-width: 769px) {
    .toolbar_navigation-items {
        display: block;
    }
    .toolbar__toggle-button {
        display: none;
    }

    .toolbar__logo {
        margin-left: 0;
    }
}
